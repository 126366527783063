<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>组织权限</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="txtSearch"
          :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
          @keydown.enter="searchEmployee()"
          label="关键字搜索（按回车）"
          prepend-inner-icon="search"
          solo
          flat
          dense
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
      <v-card-subtitle v-if="!txtSearch">组织权限可以维护运营主体内部员工组织架构和角色权限。组织架构最多可以建立三级，平台的数据访问是基于组织架构模型，同一部门内可以浏览相同的数据，上级领导可以浏览所有下属的数据。
        角色权限设定每个角色所能使用的功能，且一个人员可以设置多个角色。
      </v-card-subtitle>
      <v-tabs v-model="activeTab" grow icons-and-text v-if="!txtSearch">
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
          <router-view></router-view>
        </v-tab-item>
      </v-tabs>
      <v-data-table
        v-if="txtSearch"
        :headers="headerEmployee"
        :items="accountList"
        :options.sync="employee_options"
        :server-items-length="accountCount"
        :loading="accountLoading"
        :item-class="row_class"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
        item-key="_id"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>人员列表</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-menu offset-y v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Organize')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" depressed class="mx-2">
                  <v-icon left>add</v-icon>
                  新增人员
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="createEmployee()">
                  <v-list-item-icon>
                    <v-icon>add_box</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>通过表单录入</v-list-item-title>
                </v-list-item>
                <v-list-item @click="batchEmployee()">
                  <v-list-item-icon>
                    <v-icon>library_add</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>通过文件增加</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:item.personal.name="{ item }">
          <td nowrap="true">
            <account-dialogs
              :account="item._id"
              @UpdateEmployee="getEmployee({key: txtSearch})"
            >
              {{item.personal.name || '*未设置姓名'}}
            </account-dialogs>
          </td>
        </template>
        <template v-slot:item.personal.gender="{ item }">
          <td nowrap="true">{{ item.personal.gender ? '男' : '女' }}</td>
        </template>
        <template v-slot:item.phone="{ item }">
          <td nowrap="true">{{ item.phone }}</td>
        </template>
        <template v-slot:item.employer.corp="{ item }">
          <td nowrap="true">{{item.employer.corp ? item.employer.corp : item.employer.ownerId.name}}</td>
        </template>
        <template v-slot:item.employer.areaColor="{ item }">
          <td nowrap="true">
            <v-chip label x-small v-if="item.employer.areaColor === 0" color="green"></v-chip>
            <v-chip label x-small v-else-if="item.employer.areaColor === 1" color="yellow"></v-chip>
            <v-chip label x-small v-else color="red"></v-chip>
          </td>
        </template>
        <template v-slot:item.employer.department="{ item }">
          <td nowrap="true">{{item.employer.department.map(item => item.name)}}</td>
        </template>
        <template v-slot:item.employer.roles="{ item }">
          <td nowrap="true">{{item.employer.roles.map(item => item.name)}}</td>
        </template>
        <template v-slot:item.employer.area="{ item }">
          <td nowrap="true">{{item.employer.area.map(item => item.name)}}</td>
        </template>
        <template v-slot:item.employer.isLeave="{ item }">
          <td nowrap="true">
            <span v-if="item.employer.isLeave">离职</span>
            <span v-else>在职</span>
          </td>
        </template>
        <template v-slot:item.personal.address="{ item }">
          <td nowrap="true">{{item.personal.address}}</td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEmployee" persistent max-width="450px">
      <account-edit v-if="dialogTitle === '新增人员'" @Close="closeDialog()"></account-edit>
      <account-import v-if="dialogTitle === '导入人员'" @Close="closeDialog()"></account-import>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import {
  FETCH_ACCOUNT_LIST,
} from "@/store/actions.type";
export default {
  data() {
    return {
      activeTab: '/distributor/system/organize/department',
      tabs: [{
        id: 1, name: '组织架构', route: '/distributor/system/organize/department', help: '组织架构调整将影响用户查阅和管理数据权限的范围，不影响用户使用功能权限。', icon: 'account_tree'
      }, {
        id: 2, name: '角色权限', route: '/distributor/system/organize/roles', help: '角色权限调整将影响用户使用平台功能的范围，不影响用户查阅和管理数据范围。', icon: 'supervisor_account'
      }],
      txtSearch: '',
      headerEmployee: [
        { text: '姓名', value: 'personal.name' },
        // { text: '性别', value: 'personal.gender', width: "85px" },
        { text: '手机号码', value: 'phone' },
        // { text: '房间号码', value: 'employer.berth.code_no' },
        { text: '部门', value: 'employer.department' },
        { text: '角色', value: 'employer.roles' },
        { text: '区域', value: 'employer.area' },
        { text: '状态', value: 'employer.isLeave' }
      ],
      employee_options: {},
      dialogEmployee: false,
      dialogTitle: ''
    }
  },
  created() {},
  computed: {
    ...mapGetters([
      'currentUser',
      'accountLoading',
      'accountList',
      'accountCount']),
  },
  watch: {
    employee_options: {
      handler () {
        this.getEmployee()
      },
      deep: true,
    },
    dialogEmployee (val) {
      val || this.closeDialog()
    },
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({path: '/distributor/system'});
    },
    createEmployee() {
      this.dialogTitle = '新增人员';
      this.dialogEmployee = true;
    },
    batchEmployee() {
      this.dialogTitle = '导入人员';
      this.dialogEmployee = true;
    },
    searchEmployee() {
      if (this.txtSearch !== '') this.getEmployee({key: this.txtSearch})
    },
    getEmployee(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.employee_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      const { key, ownertype, ownerid, department, roles } = query;
      let urlQuery = '';
      if (ownertype && ownertype.abbr) urlQuery += '&ownertype='+ ownertype.abbr;
      if (department && department !== -1) urlQuery += '&department='+ department;
      if (roles && roles._id) urlQuery += '&roles='+ roles._id;
      if (ownerid) urlQuery += '&ownerid='+ ownerid;
      if (key) urlQuery += '&key='+ key;
      if (Object.keys(this.employee_options).length > 0) {
        this.$store.dispatch(FETCH_ACCOUNT_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    closeDialog() {
      if (this.txtSearch !== '') this.getEmployee({key: this.txtSearch})
      this.dialogEmployee = false
    },
    row_class(item) {
      if (item.isBlock) {
        return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
      }
    }
  },
  directives: {}
}
</script>
<style scoped>
</style>
