var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("组织权限")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
      "label": "关键字搜索（按回车）",
      "prepend-inner-icon": "search",
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchEmployee();
      }
    },
    model: {
      value: _vm.txtSearch,
      callback: function callback($$v) {
        _vm.txtSearch = $$v;
      },
      expression: "txtSearch"
    }
  })], 1), !_vm.txtSearch ? _c('v-card-subtitle', [_vm._v("组织权限可以维护运营主体内部员工组织架构和角色权限。组织架构最多可以建立三级，平台的数据访问是基于组织架构模型，同一部门内可以浏览相同的数据，上级领导可以浏览所有下属的数据。 角色权限设定每个角色所能使用的功能，且一个人员可以设置多个角色。 ")]) : _vm._e(), !_vm.txtSearch ? _c('v-tabs', {
    attrs: {
      "grow": "",
      "icons-and-text": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.id,
      attrs: {
        "to": tab.route
      }
    }, [_vm._v(" " + _vm._s(tab.name) + " "), _c('v-icon', [_vm._v(_vm._s(tab.icon))])], 1);
  }), _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab-item', {
      key: tab.id,
      attrs: {
        "value": tab.route
      }
    }, [_c('router-view')], 1);
  })], 2) : _vm._e(), _vm.txtSearch ? _c('v-data-table', {
    attrs: {
      "headers": _vm.headerEmployee,
      "items": _vm.accountList,
      "options": _vm.employee_options,
      "server-items-length": _vm.accountCount,
      "loading": _vm.accountLoading,
      "item-class": _vm.row_class,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.employee_options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("人员列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Organize') ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mx-2",
                attrs: {
                  "color": "primary",
                  "depressed": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "left": ""
                }
              }, [_vm._v("add")]), _vm._v(" 新增人员 ")], 1)];
            }
          }], null, false, 1864754999)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.createEmployee();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("add_box")])], 1), _c('v-list-item-title', [_vm._v("通过表单录入")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.batchEmployee();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("通过文件增加")])], 1)], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.personal.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('account-dialogs', {
          attrs: {
            "account": item._id
          },
          on: {
            "UpdateEmployee": function UpdateEmployee($event) {
              return _vm.getEmployee({
                key: _vm.txtSearch
              });
            }
          }
        }, [_vm._v(" " + _vm._s(item.personal.name || '*未设置姓名') + " ")])], 1)];
      }
    }, {
      key: "item.personal.gender",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.personal.gender ? '男' : '女'))])];
      }
    }, {
      key: "item.phone",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.phone))])];
      }
    }, {
      key: "item.employer.corp",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.corp ? item.employer.corp : item.employer.ownerId.name))])];
      }
    }, {
      key: "item.employer.areaColor",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.employer.areaColor === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "x-small": "",
            "color": "green"
          }
        }) : item.employer.areaColor === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "x-small": "",
            "color": "yellow"
          }
        }) : _c('v-chip', {
          attrs: {
            "label": "",
            "x-small": "",
            "color": "red"
          }
        })], 1)];
      }
    }, {
      key: "item.employer.department",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.department.map(function (item) {
          return item.name;
        })))])];
      }
    }, {
      key: "item.employer.roles",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.roles.map(function (item) {
          return item.name;
        })))])];
      }
    }, {
      key: "item.employer.area",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.employer.area.map(function (item) {
          return item.name;
        })))])];
      }
    }, {
      key: "item.employer.isLeave",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.employer.isLeave ? _c('span', [_vm._v("离职")]) : _c('span', [_vm._v("在职")])])];
      }
    }, {
      key: "item.personal.address",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.personal.address))])];
      }
    }], null, false, 1922866212)
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "450px"
    },
    model: {
      value: _vm.dialogEmployee,
      callback: function callback($$v) {
        _vm.dialogEmployee = $$v;
      },
      expression: "dialogEmployee"
    }
  }, [_vm.dialogTitle === '新增人员' ? _c('account-edit', {
    on: {
      "Close": function Close($event) {
        return _vm.closeDialog();
      }
    }
  }) : _vm._e(), _vm.dialogTitle === '导入人员' ? _c('account-import', {
    on: {
      "Close": function Close($event) {
        return _vm.closeDialog();
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }